@import 'main';
@import 'syntax';

.footer-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

a {
    text-decoration: none;
    border-bottom: none;
}
